import { GetRequestConfig } from './internal'
import tw from '~/tailwind.config'

const endpoint = process.env.CMS_ENDPOINT

export type CommonSnippet = {
  id: number
  name_lang: string
  name_de: string
  name_en: string
}

export type CommonCategory = CommonSnippet & {
  color: string
  priority: number
}

type Image = {
  url: string
  width: number
  height: number
}

type ResponsiveImage = {
  desktop_jpg: Image
  desktop_webp: Image
  'desktop@2x_jpg': Image
  'desktop@2x_webp': Image
  mobile_jpg: Image
  mobile_webp: Image
  'mobile@2x_jpg': Image
  'mobile@2x_webp': Image
  tablet_jpg: Image
  tablet_webp: Image
  'tablet@2x_jpg': Image
  'tablet@2x_webp': Image
}

type OnesizeImage = {
  onesize_jpg: Image
  onesize_webp: Image
  'onesize@2x_jpg': Image
  'onesize@2x_webp': Image
}

export const cmsImageToSources = (
  image: ResponsiveImage | OnesizeImage
): unknown[] => {
  return Object.entries(image).map(([name, image]) => ({
    url: `${process.env.CMS_ENDPOINT}${image.url}`,
    scale: name.includes('@2x') ? 2 : 1,
    width: name.startsWith('desktop')
      ? parseInt(tw.theme.screens.xl)
      : name.startsWith('tablet')
      ? parseInt(tw.theme.screens.md)
      : 0,
    type: name.split('_')[1],
  }))
}

export type CmsDetailImage = {
  card: ResponsiveImage
  stage: ResponsiveImage
}

export type CmsTrainerImage = {
  card: OnesizeImage
  stage: ResponsiveImage
}

type Teaser = {
  title_de: string
  title_en: string
  description_de: string
  description_en: string
  url: string
  image: never
  btn_link_de: string
  btn_link_en: string
  btn_text_de: string
  btn_text_en: string
}

export const cmsBodyFocusAreas = (): GetRequestConfig => ({
  url: `${endpoint}/api/snippets/body_focus_areas/all.json`,
  config: {},
})

export const cmsGoals = (): GetRequestConfig => ({
  url: `${endpoint}/api/snippets/goals/all.json`,
  config: {},
})

export const cmsIntensities = (): GetRequestConfig => ({
  url: `${endpoint}/api/snippets/intensities/all.json`,
  config: {},
})

export const cmsLevels = (): GetRequestConfig => ({
  url: `${endpoint}/api/snippets/levels/all.json`,
  config: {},
})

export const cmsSports = (): GetRequestConfig => ({
  url: `${endpoint}/api/snippets/sports/all.json`,
  config: {},
})

// Pages
export type Locale = 'de' | 'en'
export type Membership = 'BASIC' | 'PREMIUM'

type PlaylistVideoEntry = {
  uuid: string
  title: string
  type: 'video'
  category: 'WORKOUT' | 'INTERVIEW' | 'QUICKFACT'
}

type PlaylistRestEntry = {
  type: 'rest'
  value: 2
}

type CollectionPlaylistEntry = {
  uuid: string
  title: string
}

export type HomepageCollectionPlaylist = {
  duration: string
  goal: CommonSnippet
  uuid: string
  image: never
  level: CommonSnippet
  title: string
}

export type HomepageCollectionEntry = {
  id: number
  is_smart: boolean
  smart: HomepageCollectionPlaylist[]
  title: string
  type: 'selection'
  playlists: HomepageCollectionPlaylist[]
}

type HomepagePlaylistRestEntry = {
  type: 'rest'
  value: number
}

type HomepagePlaylistVideoEntry = {
  calories: number
  duration: number
  id: number
  image: CmsDetailImage
  intensity: CommonSnippet
  sport: CommonSnippet
  title: string
  type: 'video'
}

type HomepagePlaylistEntry = {
  entries: (HomepagePlaylistRestEntry | HomepagePlaylistVideoEntry)[]
  id: number
  image: CmsDetailImage
  is_smart: boolean
  smart: HomepagePlaylistVideoEntry[]
  title: string
  type: 'playlist'
}

type HomepageTeaserEntry = {
  type: 'teaser'
  id: number
  title_de: string
  title_en: string
  description_de: string
  description_en: string
  image: never
  btn_link_de: string
  btn_link_en: string
  btn_text_de: string
  btn_text_en: string
}

type DetailMeta<
  T extends
    | 'video.SelectionDetail'
    | 'video.PlaylistDetail'
    | 'video.VideoDetail'
    | 'video.TrainerDetail'
    | 'home.HomePage'
> = {
  type: T
  html_url: string
  slug: string
  show_in_menus: string
  seo_title: string
  search_description: string
  first_published_at: string
  alias_of: number
  locale: Locale
}

type SelectionDetail = {
  description: string
  id: number
  is_smart: boolean
  meta: DetailMeta<'video.SelectionDetail'>
  playlists: CollectionPlaylistEntry[]
  smart: CollectionPlaylistEntry[]
  title: string
  uuid: string
  color: string
}

type PlaylistDetail = {
  category: CommonSnippet
  description: string
  duration: number
  entries: (PlaylistVideoEntry | PlaylistRestEntry)[]
  goal: CommonSnippet
  id: number
  image: CmsDetailImage
  is_smart: boolean
  level: CommonSnippet
  meta: DetailMeta<'video.PlaylistDetail'>
  smart: PlaylistVideoEntry[]
  sports: {
    id: number
  }[]
  title: string
  uuid: string
}

type Trainer = {
  id: number
  title: string
  uuid: string
  image: CmsTrainerImage
}

type VideoDetail = {
  body_focus: CommonSnippet
  calories: number
  category: 'WORKOUT' | 'INTERVIEW' | 'QUICKFACT'
  description: string
  duration: number
  equipment: CommonSnippet[]
  image: CmsDetailImage
  intensity: CommonSnippet
  membership: Membership
  playback_id: string
  signature: string
  sport: CommonSnippet
  trainers: Trainer[]
  uuid: string
  title: string
}

export type TrainerDetail = {
  id: number
  meta: DetailMeta<'video.TrainerDetail'>
  title: string
  description: string
  image: CmsTrainerImage
  priority: number
  quote: string
  sports: CommonSnippet[]
  uuid: string
}

type ResponseList<T> = {
  items: T[]
  meta: {
    total_count: 1
  }
}

type CmsHomePageRequest = {
  locale: Locale
}

export type CmsHomePageResponse = {
  items: {
    entries: (
      | HomepageCollectionEntry
      | HomepagePlaylistEntry
      | HomepageTeaserEntry
    )[]
    id: number
    meta: DetailMeta<'home.HomePage'>
    title: string
  }[]
  meta: {
    total_count: 1
  }
}

export const cmsHomePage = ({
  locale = 'en',
}: CmsHomePageRequest): GetRequestConfig => ({
  url: `${endpoint}/api/pages/home_HomePage/${locale}/1.json`,
  config: {},
})

type CmsSelectionDetailRequest = {
  locale: Locale
  page: number
}

export type CmsSelectionDetailResponse = ResponseList<SelectionDetail>

export const cmsSelectionDetail = ({
  locale = 'en',
  page = 1,
}: CmsSelectionDetailRequest): GetRequestConfig => ({
  url: `${process.env.CMS_ENDPOINT}/api/pages/video_SelectionDetail/${locale}/${page}.json`,
  config: {},
})

type CmsTrainerDetailRequest = {
  locale: Locale
  page: number
}

export type CmsTrainerDetailResponse = ResponseList<TrainerDetail>

export const cmsTrainerDetail = ({
  locale = 'en',
  page = 1,
}: CmsTrainerDetailRequest): GetRequestConfig => ({
  url: `${endpoint}/api/pages/video_TrainerDetail/${locale}/${page}.json`,
  config: {},
})

type TrainerDetailWithQuotes = {
  image: CmsTrainerImage
  title: string
  quote: string
}

type CmsTrainerDetailWithQuotesRequest = {
  locale: Locale
}

export type CmsTrainerDetailWithQuotesResponse = TrainerDetailWithQuotes[]

export const cmsTrainerDetailWithQuotes = ({
  locale = 'en',
}: CmsTrainerDetailWithQuotesRequest): GetRequestConfig => ({
  url: `${endpoint}/api/pages/video_TrainerDetail_with_quotes/${locale}/all.json`,
  config: {},
})

export type SelectionDetailPageResponse = SelectionDetail

export type PlaylistDetailPageResponse = PlaylistDetail & {
  meta: DetailMeta<'video.PlaylistDetail'>
}

export type VideoDetailPageResponse = VideoDetail & {
  meta: DetailMeta<'video.VideoDetail'>
}

export type TrainerDetailPageResponse = TrainerDetail & {
  meta: DetailMeta<'video.TrainerDetail'>
}

type CmsDetailPageRequest = {
  uuid: string
}

export const cmsDetailPage = ({
  uuid,
}: CmsDetailPageRequest): GetRequestConfig => ({
  url: `${endpoint}/api/pages/detail/${uuid}.json`,
  config: {},
})

export type LocalizedCommonSnippet = {
  id: number
  name_lang: string
  name: string
}

export type LocalizedCommonCategory = LocalizedCommonSnippet & CommonCategory

export type LocalizedTeaser = {
  title: string
  description: string
  url: string
  image: never
  btn_link: string
  btn_text: string
}

export type LocalizedHomepageTeaserEntry = {
  type: 'teaser'
  id: number
  title: string
  description: string
  image: never
  btn_link: string
  btn_text: string
}

type CmsFindByXRequest = {
  id: string
  locale: Locale
  page: number
}

export type PlanEntry = {
  category: string
  duration: number
  goals: CommonSnippet[]
  image: any
  level: CommonSnippet[]
  title: string
  uuid: string
  video_count: number
}

export type CmsFindPlaylistResponse = ResponseList<PlanEntry>
export type CmsFindCollectionsByGoalResponse = CmsFindPlaylistResponse

export const cmsFindCollectionsByGoal = ({
  id,
  locale = 'en',
  page = 1,
}: CmsFindByXRequest): GetRequestConfig => ({
  url: `${endpoint}/api/pages/video_PlaylistList_Collections_by_goal/${id}/${locale}/${page}.json`,
  config: {},
})

export type CmsFindCollectionsByLevelResponse = CmsFindPlaylistResponse

export const cmsFindCollectionsByLevel = ({
  id,
  locale = 'en',
  page = 1,
}: CmsFindByXRequest): GetRequestConfig => ({
  url: `${endpoint}/api/pages/video_PlaylistList_Collections_by_level/${id}/${locale}/${page}.json`,
  config: {},
})

export type CmsFindPlansByGoalResponse = CmsFindPlaylistResponse

export const cmsFindPlansByGoal = ({
  id,
  locale = 'en',
  page = 1,
}: CmsFindByXRequest): GetRequestConfig => ({
  url: `${endpoint}/api/pages/video_PlaylistList_Plans_by_goal/${id}/${locale}/${page}.json`,
  config: {},
})

export type CmsFindPlansByLevelResponse = CmsFindPlaylistResponse

export const cmsFindPlansByLevel = ({
  id,
  locale = 'en',
  page = 1,
}: CmsFindByXRequest): GetRequestConfig => ({
  url: `${endpoint}/api/pages/video_PlaylistList_Plans_by_level/${id}/${locale}/${page}.json`,
  config: {},
})

export type ListOfVideoDetails = ResponseList<VideoDetail & { viewedAt?: Date }>
// same as above but viewedAt is defined
export type ListOfVideoDetailsWithDate = ResponseList<
  VideoDetail & { viewedAt: Date }
>

export type CmsFindPlanResponse = ResponseList<
  PlanEntry & { video_uuids: string[] }
>

export const cmsFindVideosBySport = ({
  id,
  locale = 'en',
  page = 1,
}: CmsFindByXRequest): GetRequestConfig => ({
  url: `${endpoint}/api/pages/video_VideoList_by_sport/${id}/${locale}/${page}.json`,
  config: {},
})

export const cmsFindVideosByIntensity = ({
  id,
  locale = 'en',
  page = 1,
}: CmsFindByXRequest): GetRequestConfig => ({
  url: `${endpoint}/api/pages/video_VideoList_by_intensity/${id}/${locale}/${page}.json`,
  config: {},
})

export const cmsFindVideosByBodyFocusArea = ({
  id,
  locale = 'en',
  page = 1,
}: CmsFindByXRequest): GetRequestConfig => ({
  url: `${endpoint}/api/pages/video_VideoList_by_bodyfocusarea/${id}/${locale}/${page}.json`,
  config: {},
})

export const cmsFindVideosByDuration = ({
  id,
  locale = 'en',
  page = 1,
}: CmsFindByXRequest): GetRequestConfig => ({
  url: `${endpoint}/api/pages/video_VideoList_by_duration/${id}/${locale}/${page}.json`,
  config: {},
})

type CmsFindByTrainerRequest = {
  uuid: string
  locale: Locale
  page: number
}

/**
 * Collects all videos that are available for filtering (by locale and trainer)
 * @param uuid - trainer uuid
 * @param locale - locale, default: 'en'
 * @param page number, default: 1
 */
export const cmsFindVideosByTrainer = ({
  uuid,
  locale = 'en',
  page = 1,
}: CmsFindByTrainerRequest): GetRequestConfig => ({
  url: `${endpoint}/api/pages/video_VideoList_by_trainer/${uuid}/${locale}/${page}.json`,
  config: {},
})

type CmsFindAllRequest = {
  locale: Locale
  page: number
  withTrampoline: Boolean
}

/**
 * Collects all videos that are available for filtering (by locale and trampoline)
 * @param locale - locale, default: 'en'
 * @param page - page number, default: 1
 * @param withTrampoline - with or without trampoline, default: true
 */
export const cmsFindAllVideos = ({
  locale = 'en',
  page = 1,
  withTrampoline = true, // Standardmäßig setzen wir dies auf true
}: CmsFindAllRequest): GetRequestConfig => {
  // Bestimme den Basispfad abhängig vom Wert von withTrampoline
  const basePath = withTrampoline ? 'trampoline' : 'no_trampoline'

  return {
    url: `${endpoint}/api/videos/${basePath}/${locale}/${page}.json`,
    config: {},
  }
}

/**
 * Collects all videos, regardless of locale
 */
export const cmsGetAllVideos = (): GetRequestConfig => {
  return {
    url: `${endpoint}/api/videos/all/1.json`,
    config: {},
  }
}

/**
 * Collects all plans, regardless of locale
 */
export const cmsGetAllPlans = (): GetRequestConfig => {
  return {
    url: `${endpoint}/api/plans/all/1.json`,
    config: {},
  }
}

export const resolveResponseLanguage = <
  T extends CommonSnippet | CommonCategory | Teaser | HomepageTeaserEntry,
  ReturnType extends T extends CommonCategory
    ? LocalizedCommonCategory
    : T extends CommonSnippet
    ? LocalizedCommonSnippet
    : T extends Teaser
    ? LocalizedTeaser
    : LocalizedHomepageTeaserEntry
>(
  source: T,
  lang: Locale
): ReturnType => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const res = { ...source } as Record<string, any>
  for (const key in source) {
    if (key.endsWith('_de')) {
      const baseKey = key.replace('_de', '')
      res[baseKey] = source[`${baseKey}_${lang}` as keyof T]
    }
  }
  return res as ReturnType
}
