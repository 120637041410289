





































import Vue, { PropType } from 'vue'
import BSGHeadline, { looks } from './Headline.vue'
import BSGParagraph from './Paragraph.vue'
import BSGButtonGroup from './ButtonGroup.vue'
import { enumValidator } from '~/services/enum-props'

export default Vue.extend({
  name: 'BSGHeading',
  components: {
    BSGHeadline,
    BSGParagraph,
    BSGButtonGroup,
  },
  props: {
    colorTheme: {
      type: String as PropType<string>,
      default: 'light',
      validator: enumValidator(['light', 'dark']),
    },
    overline: {
      type: String as PropType<string>,
      default: '',
    },
    look: {
      type: String as PropType<string>,
      default: '1',
      validator: enumValidator(Object.keys(looks)),
    },
    headlineLevel: {
      type: String as PropType<string>,
      default: '2',
    },
    paragraphSmall: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    centered: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    buttonsReverse: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    buttonsFullWidth: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    buttonsFlip: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  computed: {
    color(): string {
      return this.colorTheme === 'dark' ? 'neutral-000' : 'neutral-800'
    },
  },
})
