




























import Vue, { PropType } from 'vue'
import BSGIcon from './Icon.vue'

export default Vue.extend({
  name: 'BSGInput',
  components: {
    BSGIcon,
  },
  props: {
    id: {
      type: String as PropType<string>,
      required: true,
    },
    value: {
      type: String as PropType<string>,
      default: undefined,
    },
    labelText: {
      type: String as PropType<string>,
      default: undefined,
    },
    passwordType: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    errorMessage: {
      type: String as PropType<string>,
      default: '',
    },
  },
  data() {
    return {
      showPassword: false,
    };
  },
  methods: {
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
  },
})
