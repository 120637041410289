


















































import Vue, { PropType } from 'vue'
import tw from '~/tailwind.config'
import { enumValidator } from '~/services/enum-props'

export type ImageCustomSource = {
  url: string
  scale: 1 | 2
  width: number
  type: string
}

export type Image = {
  name: string | ImageCustomSource[]
  alt?: string
  type?: string
  loading?: string
}

/**
 * Create images with this name convention:
 * /{image|svg}/$name/{onesize|(desktop|tablet|mobile)}{|@2x}.{jpg|webp|svg}
 *
 * You have to organize your files into folders:
 * name: "module/folder" => "image/module/folder/..."
 *
 * If name ends with "#onesize", resolution switching is disabled
 * "module/name#onesize"
 */
export default Vue.extend({
  name: 'BSGImageRaw',
  props: {
    name: {
      /**
       * If string, it's and internal picture
       * If string ends with "#onesize", resolution switching is disabled
       *
       * If array, it's possible to use custom sources for external services
       */
      type: [String, Array] as PropType<string | ImageCustomSource[]>,
      required: true,
    },
    alt: {
      type: String as PropType<string>,
      default: '',
    },
    type: {
      type: String,
      default: 'jpg',
      validator: enumValidator(['jpg', 'png', 'svg']),
    },
    /**
     * Avoid lazy-loading images that are in the first visible viewport
     */
    loading: {
      type: String as PropType<string>,
      default: 'lazy',
      validator: enumValidator(['auto', 'eager', 'lazy', 'custom-lazy']),
    },
    fullscreen: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  computed: {
    dpi(): { [key: string]: number } {
      if (this.type !== 'svg') {
        return {
          '': 1,
          '@2x': 2,
        }
      } else {
        return {
          '': 1,
        }
      }
    },
    fallbackSrc(): string {
      // do not render img tag if name is empty
      if (!this.name || this.name.length === 0) return ''

      // for custom sources, use first element as fallback
      if (Array.isArray(this.name)) {
        return this.name[0].url
      } else {
        // we use the smalles version as fallback
        const isOneSize = this.name.endsWith('#onesize')
        const fallback = isOneSize ? 'onesize' : 'mobile'
        const name = isOneSize ? this.name.replace('#onesize', '') : this.name
        return `${this.staticPath}${name}/${fallback}.${this.type}`
      }
    },
    staticPath(): string {
      const path = this.type === 'svg' ? 'svg' : `image`
      return `${this.$config.IMAGE_CDN}${path}/`
    },
    resolutions(): { name: string; width: string }[] {
      if (Array.isArray(this.name) || !this.name) return []
      if (this.name.endsWith('#onesize')) {
        return [{ name: 'onesize', width: '0px' }]
      } else {
        return [
          { name: 'desktop', width: tw.theme.screens.xl },
          { name: 'tablet', width: tw.theme.screens.md },
          { name: 'mobile', width: '0px' },
        ]
      }
    },
    sourceType(): string {
      return this.type === 'svg' ? 'svg+xml' : this.type
    },
    customSources(): { srcset: string; type: string; media: string }[] {
      const sources = [] as { srcset: string; type: string; media: string }[]
      if (Array.isArray(this.name)) {
        const group = this.name.reduce((acc, current) => {
          const type = current.type
          const width = current.width
          if (!acc[type]) acc[type] = {}
          if (!acc[type][width]) acc[type][width] = []
          acc[type][width].push(current)
          return acc
        }, {} as { [type: string]: { [width: string]: ImageCustomSource[] } })

        Object.keys(group)
          .sort((a, b) => (a === 'webp' ? -1 : b === 'webp' ? 1 : 0))
          .forEach((type) => {
            Object.keys(group[type])
              .sort((a, b) => (parseInt(a) > parseInt(b) ? -1 : 1))
              .forEach((width) => {
                const scales = [] as string[]
                group[type][width].forEach((source) => {
                  scales.push(
                    `${source.url}${
                      source.scale > 1 ? ` ${source.scale}x` : ''
                    }`
                  )
                })
                sources.push({
                  srcset: scales.join(', '),
                  type,
                  media: `${width}px`,
                })
              })
          })
      }
      return sources
    },
  },
  methods: {
    sourcePath(file: string, dpi: number): string {
      const dpiString = dpi > 1 ? ` ${dpi}x` : ''
      if (!Array.isArray(this.name)) {
        file = `${this.staticPath}${this.name.replace('#onesize', '')}/${file}`
      }
      return `${file}${dpiString}`
    },
    srcSet(resolutionName: string, isWebP?: boolean) {
      const sources = []
      for (const key in this.dpi) {
        sources.push(
          this.sourcePath(
            `${resolutionName}${key}.${isWebP ? 'webp' : this.type}`,
            this.dpi[key]
          )
        )
      }
      return sources.join(', ')
    },
  },
})
