





















































import Vue, { PropType } from 'vue'
import { enumValidator } from '~/services/enum-props'
import { getColorNames, getColorStyle } from '~/styleguide/utils'

const radius = 200
const topCenterOffset = 10

export default Vue.extend({
  name: 'CommonVideoStage',
  props: {
    alignment: {
      type: String as PropType<'top' | 'left'>,
      default: 'top',
    },
    image: {
      type: Array,
      default: undefined,
    },
    gradientColorStart: {
      type: String as PropType<string>,
      default: 'orange-400',
      validator: enumValidator(getColorNames()),
    },
    gradientColorStop: {
      type: String as PropType<string>,
      default: 'yellow-300',
      validator: enumValidator(getColorNames()),
    },
    noBorder: { type: Boolean as PropType<boolean>, default: false },
    noOverflow: { type: Boolean as PropType<boolean>, default: false },
  },
  data() {
    return { isMounted: false }
  },
  computed: {
    // maskString(): string {
    //   return `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'><circle r='${
    //     this.circle.r
    //   }' cx='${this.circle.cx}' cy='${
    //     this.circle.cy + 100
    //   }' fill='white' /></svg>")`
    // },
    maskString(): string {
      return `circle(${this.circle.r}vw at ${this.circle.cx}vw calc(100% + ${this.circle.cy}vw))`
    },
    svgStyle(): Object {
      if (this.alignment === 'top')
        return {
          width: '100vw',
        }
      else
        return {
          height: '100vh',
        }
    },
    circle(): { r: number; cx: number; cy: number; fill: string } {
      if (this.alignment === 'top')
        return {
          r: radius,
          cx: 50 + topCenterOffset,
          cy: -radius,
          fill: 'url(#common-video-stage-gradient)',
        }
      else
        return {
          r: radius,
          cx: -radius,
          cy: 50,
          fill: 'url(#common-video-stage-gradient)',
        }
    },
    gradient(): Object {
      const widthInScreens = (radius * 2) / 100
      const relativeOffset = (widthInScreens - 1) / 2 / widthInScreens
      if (this.alignment === 'top')
        return {
          x1: relativeOffset,
          y1: 0,
          x2: 1 - relativeOffset,
          y2: 0,
          id: 'common-video-stage-gradient',
        }
      else
        return {
          x1: 0,
          y1: 1 - relativeOffset,
          x2: 0,
          y2: relativeOffset,
          id: 'common-video-stage-gradient',
        }
    },
    gradientColorStartValue(): string {
      return getColorStyle(this.gradientColorStart).color!
    },
    gradientColorStopValue(): string {
      return getColorStyle(this.gradientColorStop).color!
    },
  },
  mounted() {
    this.isMounted = true
  },
})
